import React , {useState} from "react";
import { Redirect } from "react-router-dom";
import {
    Form,
    Button,
    Grid,
    Header as SemanticHeader,
    Segment,
  } from "semantic-ui-react";
import firebase from "../firebase/config";
import { Auth } from "../context/authContext";

const Login = () => {
    const [form, setForm] = useState({});
    const [routeRedirect, setRouteRedirect] = useState(false);

    const { dispatch } = React.useContext(Auth);

    const loginFormValid =
    !form.password?.length ||
    !form.email?.length;

    const login = async(e) => {
        e.preventDefault();
        let response = await firebase.login(form);
        if(response.hasOwnProperty("message")){
            console.log(response.message);
        }else{
            //console.log(response.user);
            setRouteRedirect(true);
            return dispatch({
                type: "LOGIN",
                payload: response.user
            });
           
        }



    }

    const onChange = (e, { name, value }) => {
        setForm({ ...form, [name]: value });
      };

    const redirect = routeRedirect;
    if(redirect){
        return <Redirect to="/" />  
    }

    return(
        <React.Fragment>
        <Grid centered>
        <Grid.Column style={{ maxWidth: 550, marginTop: 20 }}>
          <SemanticHeader>Login to your account</SemanticHeader>
          <Segment>
            <Form>
              {/* {error && <Message content={error?.detail} negative />} */}
              <Form.Field>
                <Form.Input
                  value={form.email || ""}
                  onChange={onChange}
                  name="email"
                  placeholder="Email"
                  label="Email"
                />
              </Form.Field>

              <Form.Field>
                <Form.Input
                  value={form.password || ""}
                  onChange={onChange}
                  type="password"
                  name="password"
                  placeholder="Password"
                  label="Password"
                />
              </Form.Field>

              <Button
                onClick={login}
                disabled={loginFormValid}
                fluid
                //loading={loading}
                primary
                type="submit"
              >
                Submit
              </Button>

              {/* <Segment>
                Need an account <Link to="/signup">Register</Link>.
              </Segment> */}
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    
        </React.Fragment>

    )


}

export default Login;
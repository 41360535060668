import React , {useEffect, useState, useRef} from "react";
import { Redirect } from 'react-router';
import firebase from "../firebase/config";
import S3 from "react-aws-s3";
import { config } from "../s3/config";
import { guidGenerator } from "./GuidGenerator";
import {
    Confirm,
    Icon,
    Dimmer,
    Loader
  } from "semantic-ui-react";
  import DetailTableUI from "./Edit/detailTable";
  import EditTableUI from "./Edit/editTable";

const PostDeatils = (props) => {
    const ReactS3Client = new S3(config);
    const [timer, setTimer] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [userState, setUserState] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [post, setPost] = useState("");
    const [car, setCar] = useState({});
    const [postid, setPostId] = useState("");
    const [routeRedirect, setRedirect] = useState(false);
    const [open, setOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [tempImages, setTempImages] = useState([]);
    const coverImagePickRef = useRef(null);
    const panoramicImagePickRef = useRef(null);
    const multipleImageRef = useRef(null);

  const getPost = async(postid) => {
      const _post = await firebase.getPost(postid).catch(err =>{
          console.log(err);
          return err;
      });

      setPost(_post);
  }

  useEffect(() => {
    setTimer(true);
    setPostId(props.match.params.id);
    getPost(props.match.params.id); 
    
    firebase.getUserState().then(user => {
        if(user){
            setUserState(user);
        }
    });
    setTimeout(() => setTimer(false), 1000);
 },[props.match.params.id]);


 const redirect = routeRedirect;
 if(redirect){
     return <Redirect to="/" />  
 }


 let currentPost;
 let editButton;

 const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat('en-IN');
    return formatter.format(number);
  }

 const handleInputChange = (e, { name, value }) => {
    switch (name) {
        case 'price':
            if (value === '' || parseInt(value) === +value) {
              setCar((prevState) => ({
                ...prevState,
                [name]: toCurrency(value)
              }));
            }
            break;
        default:
            setCar((prevState) => ({
                ...prevState,
                [name]: value
            }));
    }
    //setCar({ ...car, [name]: value });
  };


 const updateCurrentPost = async(e) => {
  e.preventDefault();
  setIsBusy(true);
  const _post = {
      brand: car.brand? car.brand : post.brand,
      body_type: car.body_type? car.body_type : post.body_type,
      color: car.color? car.color : post.color,
      condition: car.condition? car.condition : post.condition,
      doors: car.doors? car.doors : post.doors,
      engine_type: car.engine_type? car.engine_type : post.engine_type,
      model: car.model? car.model : post.model,
      power: car.power? car.power : post.power,
      specification: car.specification? car.specification : post.specification,
      torque: car.torque? car.torque : post.torque,
      transmission: car.transmission ? car.transmission : post.transmission,
      z_h: car.z_h? car.z_h : post.z_h,
      km_driven: car.km_driven ? car.km_driven : post.km_driven,
      price: car.price? car.price : post.price,
      fuel: car.fuel ? car.fuel : post.fuel,
      mfd_year: car.mfd_year ? car.mfd_year : post.mfd_year,
      isSold: car.isSold ? car.isSold : post.isSold,
      isPrice: car.isPrice ? car.isPrice : post.isPrice,
      title: car.title ? car.title : post.title,
      code: car.code ? car.code : post.code,
      extras: car.extras ? car.extras : post.extras
      //price: priceRef.current.value
  }

  firebase.updatePost(postid, _post).then(() => {
      console.log("post updated");
      setIsBusy(false);
      setRedirect(true);
  }).catch(err => {
      setIsBusy(false);
      console.log(err);
  });

}

const onCoverImageChange = async (e) => {
    e.persist();
    setIsBusy(true);
    let coverURL;
    let newCover = e.target.files[0];

    let coverFileName = guidGenerator().toString();
    await ReactS3Client.uploadFile(newCover, coverFileName).then((data) => {
      console.log(data);
      coverURL = data.location;
      if (data.status === 204) {
        console.log("success");
      } else {
        console.log("fail");
      }
    });

    firebase.updateImage(coverURL, postid, 'cover_pic', 'coverFileRef').then(() => {
        console.log("cover_pic updated");
        setIsBusy(false);
        setRedirect(true);
    }).catch(err => {
        setIsBusy(false);
        console.log(err);
    });
}

const onPanoramicImageChange = async (e) => {
    e.persist();
    setIsBusy(true);
    let panoramicURL;
    let newPanoramic = e.target.files[0];

    let panoramicFileName = guidGenerator().toString();
    await ReactS3Client.uploadFile(newPanoramic, panoramicFileName).then((data) => {
      console.log(data);
      panoramicURL = data.location;
      if (data.status === 204) {
        console.log("success");
      } else {
        console.log("fail");
      }
    });

    firebase.updateImage(panoramicURL, postid, 'panoramic', 'panoramicFileRef').then(() => {
        console.log("panoramic image updated");
        setIsBusy(false);
        setRedirect(true);
    }).catch(err => {
        setIsBusy(false);
        console.log(err);
    });
  };

  const handleDeleteImage = (url) => {
    setIsBusy(true);
    firebase.deleteImageFromArray(postid, url).then(() => {
        setIsBusy(false);
        getPost(postid);
    }).catch(err => {
        setIsBusy(false);
        console.log(err);
    })
  };

  const deleteTempImages = (url) => {
    let tempImageArray = [...tempImages];
    let newArray = [...images];
    let index = tempImages.indexOf(url);
    if (index !== -1) {console.log(tempImages.indexOf(url))
      tempImageArray.splice(index, 1);
      newArray.splice(index,1)
      setTempImages(tempImageArray);
      setImages(newArray);
    }
  };

const handleMultipleImageChange =(e) => {
    e.persist();
    const objectURL = [];
    for (let i = 0; i < multipleImageRef.current.files.length; i++) {
      const imageFile = multipleImageRef.current.files[i];
      imageFile["id"] = Math.random();
   // add an "id" property to each File object
      setImages(prevState => [...prevState, imageFile]);
      if (imageFile) {
        objectURL.push({[imageFile.id] : URL.createObjectURL(imageFile)});
      }
    }
    setTempImages(objectURL);
  };

const onMultipleImageChange =(e) => {
    e.persist();
    setIsBusy(true);
    const imageUrlArray = [];
    //const promises = [];
        if(images.length > 0) {console.log('Images und')
            let requests = images.reduce((promiseChain, file) => {
                return promiseChain.then(() => new Promise((resolve) => {
                  asyncFunction(file, resolve);
                }));
            }, Promise.resolve());
            requests.then(() => {
                firebase.updateMultipleImage(imageUrlArray, postid).then((car) => {
                setIsBusy(false);
                setRedirect(true);
                }).catch(err => {
                    console.log(err);
                    setIsBusy(false); 
                });
              });
            
            function asyncFunction (file, cb) {
                setTimeout(() => {
                    let newFileName = guidGenerator().toString();
                    ReactS3Client.uploadFile(file, newFileName).then((data) => {
                        console.log(data);
                        imageUrlArray.push(data.location);
                        if (data.status === 204) {
                            cb();
                          console.log("success");
                        } else {
                          console.log("fail");
                        }
                      });
        
                }, 100);
              }


        } else {
          setIsBusy(false);
        }
  };



const toggleEditMode = () => {
  setEditMode(!editMode);
}

const toggleShow = () => {
    setOpen(!open);
}

const  toggleConfirm = () => {
    firebase.deletePost(postid, post.coverFileRef)
    .then(() => {
        setRedirect(true);
    }).catch(err => {
        console.log(err);
    })

    toggleShow();
}
const toggleCancel = () => {
    toggleShow();
}

const deleteCurrentPost = () =>{
    toggleShow();
}


let updateForm;
let detailForm;

if(editMode){
    if(isBusy){
        updateForm = (
            <React.Fragment>
                <Dimmer active inverted>
                    <Loader>Loading</Loader>
                </Dimmer>
            </React.Fragment>
        )
    }else{
        updateForm = (
           <EditTableUI
                post={post}
                handleInputChange={handleInputChange}
                updateCurrentPost={updateCurrentPost}
                multipleImageRef={multipleImageRef}
                handleMultipleImageChange={handleMultipleImageChange}
                onMultipleImageChange={onMultipleImageChange}
                coverImagePickRef={coverImagePickRef}
                onCoverImageChange={onCoverImageChange}
                panoramicImagePickRef={panoramicImagePickRef}
                onPanoramicImageChange={onPanoramicImageChange}
           />
        )
    }

} else {
    detailForm = (<DetailTableUI post={post}/>)
}




if(timer){
  currentPost = (
      <div className="processing">
          <p>Loading Post</p>
          <div className="loader">Loading...</div>
      </div>
  )
}else{

  if(userState){
      editButton =  <button className="ui button" onClick={(e) =>toggleEditMode()}>{editMode ? "Undo Edit" :"Edit Post"}</button>;
  }

  currentPost = (
      <div className="ui grid container">
      <div className="ui placeholder segment">
        <div className="ui two column stackable center aligned grid">
          <div className="ui vertical divider"></div>
          <div className="middle aligned row">
            <div className="column lp">
              <img className="ui fluid image" src={post.cover_pic} alt="Cover Pic" />
              <div className="ui divider"></div>
              <section className="ui four stackable cards">
                <article className="PhotoCard ui card">
                    <img src={post.panoramic} className="ui fluid image" alt="Panoramic Pic"/>
                    <div className="content">
                            360 Degree image 
                        </div>
                </article>
                {post.images && post.images.map((image,index) => (
                     <article key={index} className="PhotoCard ui card">
                        <Icon name="close" link={true} bordered={true} onClick={() => handleDeleteImage(image)}/>
                        <img src={image} className="ui fluid image" alt="Interior Pic"/>
                        <div className="content">
                            Interior 
                        </div>
                     </article> 
                ))}
                {tempImages && tempImages.map((image,index) => (
                   <article key={index} className="PhotoCard ui card">
                     <Icon name="close" link={true} bordered={true} onClick={() => deleteTempImages(image)}/>
                      <img src={Object.values(image)} className="ui fluid image" alt="Interior Pic upload"/>
                   </article> 
              ))}
                </section>
            </div>
            <div className="column rp">
                {detailForm}
                {updateForm}
                <div className="ui buttons">
                    {editButton}
                    <div className="or"></div>
                    <button className="ui positive button" onClick={(e) => deleteCurrentPost()}>Delete</button>
                </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  )
} 

return(
  <React.Fragment>
        <Confirm
          open={open}
          onCancel={toggleCancel}
          onConfirm={toggleConfirm}
        />
     {currentPost}
  </React.Fragment>
);

}


export default PostDeatils;
import React from "react";

const DetailTableUI = ({
    post : {
        brand,
        model, 
        body_type, 
        price, 
        title, 
        code, 
        doors, 
        power, 
        engine_type, 
        transmission, 
        color, 
        specification, 
        condition, 
        z_h, 
        torque, 
        km_driven, 
        fuel, 
        mfd_year, 
        extras
    }
}) => {
    return (
        <React.Fragment>
            <h1>{brand} {model}</h1>
            <h2 className="ui teal tag label">Body Type : {body_type}</h2>
            <h3 className="ui brown block header">Price : AED {price}</h3>
                <table className="ui celled table">
                <tbody>
                    <tr>
                        <td ><b>Title</b></td>
                        <td >{title}</td>
                    </tr>
                    <tr>
                        <td ><b>Code</b></td>
                        <td >{code}</td>
                    </tr>
                    <tr>
                        <td ><b>Doors</b></td>
                        <td >{doors}</td>
                    </tr>
                    <tr>
                        <td ><b>Power</b></td>
                        <td >{power} Horsepower</td>
                    </tr>
                    <tr>
                        <td ><b>Engine Type</b></td>
                        <td >{engine_type}</td>
                    </tr>
                    <tr>
                        <td ><b>Transmission</b></td>
                        <td >{transmission}</td>
                    </tr>
                    <tr>
                        <td ><b>Color</b></td>
                        <td >{color}</td>
                    </tr>
                    <tr>
                        <td ><b>Specification</b></td>
                        <td >{specification}</td>
                    </tr>
                    <tr>
                        <td ><b>Condition</b></td>
                        <td >{condition}</td>
                    </tr>
                    <tr>
                        <td ><b>0 - 100 km/h</b></td>
                        <td >approx {z_h}s</td>
                    </tr>
                    <tr>
                        <td ><b>Torque</b></td>
                        <td >{torque} N M</td>
                    </tr>
                    <tr>
                        <td ><b>KM Driven</b></td>
                        <td >{km_driven} K M</td>
                    </tr>
                    <tr>
                        <td ><b>Fuel</b></td>
                        <td >{fuel}</td>
                    </tr>
                    <tr>
                        <td ><b>Manufacturing Year</b></td>
                        <td >{mfd_year}</td>
                    </tr>
                    <tr>
                        <td ><b>Extras</b></td>
                        <td >{extras}</td>
                    </tr>
                </tbody>
                </table>
    </React.Fragment>
    )
}

export default DetailTableUI;
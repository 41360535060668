export const firebaseAuth = (state, {type, payload}) => {
    switch(type){
        case "SIGNUP":
            return {...state, user: payload}

        case "LOGIN":
            return {...state, user: payload}
            
        case "LOGOUT": 
            return {...state, user: payload}    

        default: 
            return state;    

    }
}
import React, {useState, useEffect, useContext} from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu, Image, Button, Icon, Input } from "semantic-ui-react";
import firebase from "../firebase/config";
import { Auth } from "../context/authContext";
import { Posts } from "../context/postsContext";
import searchCars from "../context/actions/cars/searchCars";

const LOGO = "https://storage.googleapis.com/ares-profile-pictures/hd/f1rstmotors-4e49cb9bbfbaebaaedbac7a0935dff0e_hd.jpg";

const Nav = (props) => {
    
    const [userState, setUserState] = useState(null);
    const [userEmail, setUserEmail] = useState("");
    
    const {state, dispatch} = useContext(Auth);
    const { carDispatch: dispatch1 } = useContext(Posts);

    useEffect(() => {
        firebase.getUserState().then(user => {
            if(user){
                setUserState(user);
                setUserEmail(user.email);
            }
        });
    });

    const logout = () => {
        firebase.logout();
        setUserState(null);
        props.history.replace("/login");
        return dispatch({
            type: "LOGOUT",
            payload: {}
        });
    }

    const onChange = (e, { value }) => {
        const searchText = value.trim().replace(/" "/g, "");
        searchCars(searchText)(dispatch1);
      };
    
    let buttons;
    if(userState != null || state.user.hasOwnProperty("user")){
        //console.log(state);
        buttons =  ( <React.Fragment>
                        <Menu.Item position="right">
                            <Input
                                style={{ width: 350 }}
                                placeholder="Search Code"
                                onChange={onChange}
                            />
                        </Menu.Item>
                        <Menu.Item position="right" style={{ fontSize: 16 }}>
                            {userEmail}
                        </Menu.Item>
                        <Menu.Item >
                            <Button as={Link} to="/create" primary basic icon>
                            <Icon name="add"></Icon>
                            Create Car
                            </Button>
                        </Menu.Item>
                        <Menu.Item >
                            <Button as={Link} to="/message" primary basic icon>
                            <Icon name="paper plane"></Icon>
                            Create Message
                            </Button>
                        </Menu.Item>
                        <Menu.Item>
                            <Button onClick={logout} color="red" basic icon>
                            <Icon name="sign-out"></Icon>
                            Logout
                            </Button>
                        </Menu.Item>
                    </React.Fragment> )
    }else{
        buttons = (
            <React.Fragment>
                 <Menu.Item position="right">
                    {/* <Button as={Link} to="/signup" primary basic icon>
                    <Icon name="user"></Icon>
                        SignUp
                    </Button> */}
                </Menu.Item>
                <Menu.Item>
                    <Button as={Link} to="/login" primary basic icon>
                    <Icon name="sign-in"></Icon>
                    LogIn
                    </Button>
                </Menu.Item>            
            </React.Fragment>
        )
    }



    return(
        <Menu secondary pointing>
        <Image src={LOGO} width={60} />
        <Menu.Item as={Link} to="/" style={{ fontSize: 24 }}>
          F1RST MOTORS
        </Menu.Item>
        {buttons}
      </Menu>
    )
}
export default withRouter(Nav);
import React from 'react';
import "semantic-ui-css/semantic.min.css";
import Routes from "./routes";
import NavBar from "./components/NavBar";

function App() {
  return (
    <div className="App">
      
      <NavBar/>
      <main>
        <Routes />
      </main>

    </div>
  );
}

export default App;
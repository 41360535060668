import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import firebase from "../firebase/config";
import { Message } from "semantic-ui-react";
import { Posts } from "../context/postsContext";

const Dashboard = (props) => {

    const {carState, carDispatch} = React.useContext(Posts);
    const { posts:{ data, isSearchActive, foundCars } } = carState;
    const currentCars = isSearchActive ? foundCars : data;

    const getPosts = async() => {
        //let _posts = [];
        const postsArray = await firebase.getPosts().catch(err => {
            console.log(err);
            return err;
        });
        /*
        postsArray.forEach(doc => {
            _posts.push({id:doc.id, data:doc.data});
        });
        */
        return carDispatch({
            type: "FETCH_POSTS",
            payload: postsArray
        });

    }

    useEffect(() => {
        firebase.getUserState().then(user => {
            if(!user){
                props.history.replace("/login");
            } else {
                getPosts();
            }
        })
    }, []);

    return(
        <React.Fragment>
           <div className="ui grid container">
            { currentCars && currentCars.length === 0 && (
                <Message className="messageShow" content="No Cars to show or Please Verify Code." />
            )}
            { currentCars &&
                currentCars.map(post => {
                    return (
                        <div className="four wide column" key={post.id}>
                        <Link to={"post/" + post.id}>
                            <div className="ui link cards">
                                <div className="card">
                                    <div className="ui image">
                                        <img src={post.data.cover_pic} alt={post.data.brand}/>
                                    </div>
                                    <div className="content">
                                        <div className="header">{post.data.brand} {post.data.model}</div>
                                        { post.data.isPrice ? 
                                        <div className="meta price"> AED {post.data.price}</div> : ''}
                                        <div className="meta">{post.data.body_type}</div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        </div>
                    )
            })}
           </div>
        </React.Fragment>
    );
}

export default Dashboard;
import React ,{useEffect, useState}  from "react";
import { Redirect, withRouter } from 'react-router';
import { Dimmer, Loader } from 'semantic-ui-react'
import S3 from "react-aws-s3";
import firebase from "../firebase/config";
import CarForm from './Create/CarForm';
import { config } from "../s3/config";
import { guidGenerator } from "./GuidGenerator";

const Create = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    const [routeRedirect, setRedirect] = useState(false);   
    const [cars,setCars]=useState([]);

    const handleOnSubmit = async (car) => {
      setCars([car, ...cars]);
      setIsBusy(true);

      const ReactS3Client = new S3(config);

      let coverURL = '';
      if (car.cover) {
        let coverFileName = guidGenerator().toString();
        await ReactS3Client.uploadFile(car.cover, coverFileName).then((data) => {
          console.log(data);
          coverURL = data.location;
          if (data.status === 204) {
            console.log("success");
          } else {
            console.log("fail");
          }
        });
      }
      let panoramicURL = '';
      if (car.panoramic) {
        let panoramicFileName = guidGenerator().toString();
        await ReactS3Client.uploadFile(car.panoramic, panoramicFileName).then((data) => {
          console.log(data);
          panoramicURL = data.location;
          if (data.status === 204) {
            console.log("success");
          } else {
            console.log("fail");
          }
        });
      }

//update multiple images to server
        const imageUrlArray = [];
        let images = car.images;

    function asyncFunction (file, cb) {
        setTimeout(() => {
            let newFileName = guidGenerator().toString();
            ReactS3Client.uploadFile(file, newFileName).then((data) => {
                console.log(data);
                imageUrlArray.push(data.location);
                if (data.status === 204) {
                    cb();
                  console.log("success");
                } else {
                  console.log("fail");
                }
              });

        }, 100);
      }
      
      let requests = images.reduce((promiseChain, file) => {
          return promiseChain.then(() => new Promise((resolve) => {
            asyncFunction(file, resolve);
          }));
      }, Promise.resolve());
      
      requests.then(() => {
          console.log('Multiple images done');

                firebase.createPost(coverURL, panoramicURL, imageUrlArray, car).then((response) => {
                //firebase.sendNotification('Cadillac');
                setIsBusy(false);
                setRedirect(true);
                }).catch(err => {
                    console.log(err);
                    setIsBusy(false); 
                });
        })    
    };



    useEffect(() => {
        firebase.getUserState().then(user => {
            if(!user){
                props.history.replace("/login");
            }
        })
    });


    const redirect = routeRedirect;
    if(redirect){
        return <Redirect to="/" />  
    }


    let createForm;
    if(isBusy){
        createForm = (
                    <React.Fragment>
                      <Dimmer active inverted>
                        <Loader>Loading</Loader>
                      </Dimmer>
                    </React.Fragment>

        )
    }else{
        createForm = (
          <CarForm OnSubmit={handleOnSubmit} />
        )
    }

    return (
        <React.Fragment>
            {createForm}
        </React.Fragment>
    )

}

export default withRouter(Create);
import React, { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Grid,
  Header as SemanticHeader,
  Card,
  Form,
  Button,
  Select,
  Image,
} from "semantic-ui-react";
import "./index.css";

import { Brands, BodyTypes, Color, Doors, Transmission, Fuel, RegionalSpecs } from '../../utils/constants';

const CarForm = ({ OnSubmit }) => {
  const [fileName, setFileName] = useState('');
  const [panoramicFileName, setPanoramicFileName] = useState('');
  const [images, setImages] = useState([]);
  const coverImagePickRef = useRef(null);
  const panoramicImagePickRef = useRef(null);
  const multipleImageRef = useRef(null);
  const [tempFile, setTempFile] = useState(null);
  const [tempPanoramic, setTempPanoramic] = useState(null);
  const [car, setCar] = useState(() => {
    return {
      brand: '',
      model:  '',
      body_type:  '',
      transmission:  '',
      engine_type:  '',
      torque:  '',
      color:  '',
      doors:  '',
      power:  '',
      z_h:  '',
      price:  '',
      date:  '',
      condition:  '',
      specification:  '',
      fuel:  '',
      mfd_year:  '',
      isSold:  false,
      isPrice:  false,
      code:  '',
      extras:  '',
      title:  '',
      km_driven:  ''
    };
  });

  const onCoverImageChange = (e) => {
    e.persist();
    const fileURL = e.target.files[0];
    setFileName(fileURL);
    if (fileURL) {
      setTempFile(URL.createObjectURL(fileURL));
    }
  };
  const onPanoramicImageChange = (e) => {
    e.persist();
    const fileURL = e.target.files[0];

    setPanoramicFileName(fileURL);
    if (fileURL) {
      setTempPanoramic(URL.createObjectURL(fileURL));
    }
  };

  const onMultipleImageChange =(e) => {
    e.persist();
    for (let i = 0; i < e.target.files.length; i++) {
      const imageFile = e.target.files[i];
      imageFile["id"] = Math.random();
   // add an "id" property to each File object
      setImages(prevState => [...prevState, imageFile]);
    }
  };

  const choseImage = () => {
    if (coverImagePickRef.current) {
      coverImagePickRef.current.click();
    }
  };

  const chosePanoramic = () => {
    if (panoramicImagePickRef.current) {
      panoramicImagePickRef.current.click();
    }
  };
    
  const [errorMsg, setErrorMsg] = useState('');
  const { brand, model, body_type, transmission, engine_type, torque, color, doors, price, power, z_h, condition, specification, isSold, isPrice, km_driven, fuel, mfd_year, code, extras, title } = car;

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const values = [brand, model];
    let errorMsg = '';

    const allFieldsFilled = values.every((field) => {
      const value = `${field}`.trim();
      return value !== '' && value !== '0';
    });

    if (allFieldsFilled) {
      const car = {
        id: uuidv4(),
        brand, 
        model, 
        body_type, 
        transmission, 
        engine_type, 
        torque, 
        color, 
        doors, 
        price, 
        power, 
        z_h, 
        km_driven,
        condition, 
        specification,
        fuel,
        mfd_year,
        isSold,
        isPrice,
        code,
        extras,
        title,
        date: new Date(),
        cover: fileName,
        panoramic: panoramicFileName,
        images: images
      };
      OnSubmit(car);
    } else {
      errorMsg = 'Please fill out Basic Details Brands and Model';
    }
    setErrorMsg(errorMsg);
  };

function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

function parseInput(input) {
  return Number(input.replace(/,/g, ''));
}

  const handleInputChange = (e, { name, value }) => {
    switch (name) {
      case 'power':
        if (value === '' || parseInt(value) === +value) {
          setCar((prevState) => ({
            ...prevState,
            [name]: value
          }));
        }
        break;
      case 'price':
          setCar((prevState) => ({
            ...prevState,
            [name]: numberWithCommas(parseInput(value))
          }));
        break;
    case 'torque':
      if (value === '' || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
        setCar((prevState) => ({
          ...prevState,
          [name]: value
        }));
      }
      break;
    case 'z_h':
      if (value === '' || value.match(/^\d{1,}(\.\d{0,2})?$/)) {
        setCar((prevState) => ({
          ...prevState,
          [name]: value
        }));
      }
      break;
      default:
        setCar((prevState) => ({
          ...prevState,
          [name]: value
        }));
    }
  };

  return (
    <div className="main-form">
      <Grid centered>
        <Grid.Column className="form-column">
          <SemanticHeader>Create Car Details</SemanticHeader>

          <Card fluid>
            <Card.Content>
              <Form unstackable>
              <Form.Group widths={2}>
                <input
                  onChange={onCoverImageChange}
                  ref={coverImagePickRef}
                  type="file"
                  accept="image/png, image/jpeg"
                  hidden
                />
                <div className="image-wrapper">
                  {tempFile && (
                    <Image className="contactpicture" src={tempFile} />
                  )}
                  {!tempFile && (
                    <div onClick={choseImage} className="contactpicture">
                      <span>Choose Picture</span>
                    </div>
                  )}
                </div>

                <input
                  onChange={onPanoramicImageChange}
                  ref={panoramicImagePickRef}
                  type="file"
                  accept="image/png, image/jpeg"
                  hidden
                />
                 <div className="image-wrapper">
                  {tempPanoramic && (
                    <Image className="contactpicture" src={tempPanoramic} />
                  )}
                  {!tempPanoramic && (
                    <div onClick={chosePanoramic} className="contactpicture">
                      <span>Choose Panoramic Picture</span>
                    </div>
                  )}
                  </div>
                </Form.Group>

                <Form.Group widths={2}>
                  <Form.Input
                    name="brand"
                    onChange={handleInputChange}
                    control={Select}
                    options={Brands}
                    label="Brands"
                    placeholder="Brands"
                  />
                  <Form.Input
                    label="Model"
                    name="model"
                    onChange={handleInputChange}
                    placeholder="Model"
                  />
                  <Form.Input
                    label="Title"
                    name="title"
                    onChange={handleInputChange}
                    placeholder="Title"
                  />

                </Form.Group>
                <Form.Group widths={2}>
                  <Form.Input
                    name="transmission"
                    onChange={handleInputChange}
                    control={Select}
                    options={Transmission}
                    label="Transmission"
                    placeholder="Transmission"
                  />
                  <Form.Input
                    name="torque"
                    onChange={handleInputChange}
                    label="Torque"
                    placeholder="Torque"
                  />
                  <Form.Input
                    name="code"
                    onChange={handleInputChange}
                    label="Code"
                    placeholder="Code"
                  />
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.Input
                    name="body_type"
                    onChange={handleInputChange}
                    control={Select}
                    options={BodyTypes}
                    label="BodyTypes"
                    placeholder="BodyTypes"
                  />
                  <Form.Input
                    name="specification"
                    onChange={handleInputChange}
                    control={Select}
                    options={RegionalSpecs}
                    label="Specification"
                    placeholder="Specification"
                  />
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.Input
                    name="color"
                    onChange={handleInputChange}
                    control={Select}
                    options={Color}
                    label="color"
                    placeholder="Color"
                  />
                  <Form.Input
                    name="engine_type"
                    onChange={handleInputChange}
                    label="Engine Type"
                    placeholder="Engine Type"
                  />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Input
                    name="fuel"
                    onChange={handleInputChange}
                    control={Select}
                    options={Fuel}
                    label="Fuel"
                    placeholder="Fuel"
                  />
                  <Form.Input
                    name="doors"
                    onChange={handleInputChange}
                    control={Select}
                    options={Doors}
                    label="Number Of Doors"
                    placeholder="Number Of Doors"
                  />
                  <Form.Input
                    name="condition"
                    onChange={handleInputChange}
                    label="Condition"
                    placeholder="Condition"
                  />
                </Form.Group>
                <Form.Group widths={2}>
                  <Form.Input
                    name="z_h"
                    onChange={handleInputChange}
                    label="0 - 100 km/h"
                    placeholder="0 - 100 km/h"
                  />
                  <Form.Input
                    name="km_driven"
                    onChange={handleInputChange}
                    label="KM Driven"
                    placeholder="KM Driven"
                  />
                  <Form.Input
                    name="mfd_year"
                    onChange={handleInputChange}
                    label="Manufacturing Year"
                    placeholder="Manufacturing Year"
                  />
                </Form.Group>
                <Form.Group widths={2}>
                  <div className="isPrice">
                    <Form.Checkbox
                      value={isPrice}
                      name="isPrice"
                      onChange={(e, data) => {
                          handleInputChange(e, { name: "isPrice", value: data.checked });
                      }}
                      label="Is Price"
                    />
                  </div>
                  <Form.Input
                    name="price"
                    value={car.price}
                    onChange={handleInputChange}
                    label="Price"
                    disabled = {!isPrice}
                    placeholder="Price"
                  />
                  <Form.Input
                    name="power"
                    onChange={handleInputChange}
                    label="Power"
                    placeholder="Power"
                  />
                </Form.Group>
                <Form.TextArea
                    name="extras"
                    onChange={handleInputChange}
                    label="Extras"
                    placeholder="Extras"
                  />
                <Form.Group widths={2}>
                <Button
                  content="Select Multiple car Images"
                  labelPosition="left"
                  icon="file"
                  onClick={() => multipleImageRef.current.click()}
                />
                <input
                  ref={multipleImageRef}
                  type="file"
                  accept="image/png, image/jpeg"
                  multiple
                  hidden
                  onChange={onMultipleImageChange}
                />
                </Form.Group>
                {errorMsg && <p className="errorMsg">{errorMsg}</p>}
                <Button
                  // disabled={formInvalid || loading}
                  onClick={handleOnSubmit}
                  primary
                  type="submit"
                  // loading={loading}
                >
                  Submit
                </Button>
              </Form>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>


    </div>
  );
};

export default CarForm;

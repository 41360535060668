import React from "react";
import {
    Card,
    Form,
    Button,
    Select
  } from "semantic-ui-react";
  import { Brands, BodyTypes, Color, Doors, Transmission, Fuel, RegionalSpecs } from '../../utils/constants';

const EditTableUI = ({
    handleInputChange,
    updateCurrentPost,
    multipleImageRef,
    handleMultipleImageChange,
    onMultipleImageChange,
    coverImagePickRef,
    onCoverImageChange,
    panoramicImagePickRef,
    onPanoramicImageChange,
    post :{
        brand,
        model, 
        body_type, 
        price, 
        title, 
        code, 
        doors, 
        power, 
        engine_type, 
        transmission, 
        color, 
        specification, 
        condition, 
        z_h, 
        torque, 
        km_driven, 
        fuel, 
        mfd_year, 
        extras,
        isPrice,
        isSold
    }
}) => {
    return (
        <React.Fragment>
        <p>Update Current Details</p>
        <Card fluid>
        <Card.Content>
        <Form unstackable>
        <Form.Group widths={2}>
        <Form.Input
            name="brand"
            defaultValue={brand}
            onChange={handleInputChange}
            control={Select}
            options={Brands}
            label="Brands"
            placeholder="Brands"
          />
        <Form.Input
            defaultValue={model}
            label="Model"
            name="model"
            onChange={handleInputChange}
            placeholder="Model"
          />
          </Form.Group>
          <Form.Group widths={2}>
        <Form.Input
            name="title"
            defaultValue={title}
            onChange={handleInputChange}
            label="Title"
            placeholder="Title"
          />
        <Form.Input
            defaultValue={code}
            label="Code"
            name="code"
            onChange={handleInputChange}
            placeholder="Code"
          />
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Input
                defaultValue={power}
                name="power"
                onChange={handleInputChange}
                label="Power"
                placeholder="Power"
            />
            <Form.Input
                defaultValue={transmission}
                name="transmission"
                onChange={handleInputChange}
                control={Select}
                options={Transmission}
                label="Transmission"
                placeholder="Transmission"
            />
            </Form.Group>

            <Form.Group widths={2}>
                <Form.Input
                    defaultValue={torque}
                    name="torque"
                    onChange={handleInputChange}
                    label="Torque"
                    placeholder="Torque"
                />

                <Form.Input
                    defaultValue={body_type}
                    name="body_type"
                    onChange={handleInputChange}
                    control={Select}
                    options={BodyTypes}
                    label="BodyTypes"
                    placeholder="BodyTypes"
                />
                </Form.Group>
                <Form.Group widths={2}>
                    <Form.Input
                        defaultValue={specification}
                        name="specification"
                        onChange={handleInputChange}
                        control={Select}
                        options={RegionalSpecs}
                        label="Specification"
                        placeholder="Specification"
                    />

                    <Form.Input
                        defaultValue={color}
                        name="color"
                        onChange={handleInputChange}
                        control={Select}
                        options={Color}
                        label="color"
                        placeholder="Color"
                    />
                </Form.Group>
                <Form.Group widths={2}>

                    <Form.Input
                        defaultValue={engine_type}
                        name="engine_type"
                        onChange={handleInputChange}
                        label="Engine Type"
                        placeholder="Engine Type"
                    />

                    <Form.Input
                        defaultValue={doors}
                        name="doors"
                        onChange={handleInputChange}
                        control={Select}
                        options={Doors}
                        label="Number Of Doors"
                        placeholder="Number Of Doors"
                    />
                </Form.Group>
                <Form.Group widths={2}>
                <Form.Input
                    defaultValue={condition}
                    name="condition"
                    onChange={handleInputChange}
                    label="Condition"
                    placeholder="Condition"
                />

                <Form.Input
                    defaultValue={z_h}
                    name="z_h"
                    onChange={handleInputChange}
                    label="0 - 100 km/h"
                    placeholder="0 - 100 km/h"
                />
                </Form.Group>
                <Form.Group widths={2}>
                <Form.Input
                        defaultValue={fuel}
                        name="fuel"
                        onChange={handleInputChange}
                        control={Select}
                        options={Fuel}
                        label="Fuel"
                        placeholder="Fuel"
                    />
                <Form.Input
                        defaultValue={mfd_year}
                        name="mfd_year"
                        onChange={handleInputChange}
                        label="Manufacturing Year"
                        placeholder="Manufacturing Year"
                    />
                </Form.Group>
                <Form.Group widths={2}>
                <Form.Input
                    defaultValue={price}
                    name="price"
                    onChange={handleInputChange}
                    label="Price"
                    placeholder="Price"
                    //disabled = {!post.isPrice}
                />
                <Form.Input
                    defaultValue={km_driven}
                    name="km_driven"
                    onChange={handleInputChange}
                    label="KM Driven"
                    placeholder="KM Driven"
                />
                </Form.Group>
                <Form.TextArea
                        defaultValue={extras}
                        name="extras"
                        onChange={handleInputChange}
                        label="Extras"
                        placeholder="Extras"
                    />
                <Form.Group widths={2}>
                <Form.Checkbox
                    defaultChecked={isPrice}
                    name="isPrice"
                    onChange={(e, data) => {
                        handleInputChange(e, { name: "isPrice", value: data.checked });
                    }}
                    label="Is Price"
                />
                <Form.Checkbox
                    defaultChecked={isSold}
                    name="isSold"
                    onChange={(e, data) => {
                        handleInputChange(e, { name: "isSold", value: data.checked });
                    }}
                    label="Is Sold Out"
                />
                </Form.Group>
                <Button 
                onClick={updateCurrentPost}
                primary
                type="submit"
            >
            Submit
            </Button>
                </Form>
                </Card.Content>
                </Card>

                <Card fluid>
        <Card.Content>
        <Form unstackable>
                <Form.Group widths={2}>
                    <input
                    ref={multipleImageRef}
                    type="file"
                    accept="image/png, image/jpeg"
                    multiple
                    //hidden
                    onChange={handleMultipleImageChange}
                    />

                    <Button
                    secondary
                    content="Update Multiple Images"
                    icon="file"
                    onClick={onMultipleImageChange}
                    />
                </Form.Group>
                <Form.Group widths={2}>
                <input
                    ref={coverImagePickRef}
                    type="file"
                    accept="image/png, image/jpeg"
                    //hidden
                    onChange={onCoverImageChange}
                />
                <Button
                    secondary
                    content="Update Cover Image"
                    icon="file"
                    onClick={() => coverImagePickRef.current.click()}
                    />
                </Form.Group>

                <Form.Group widths={2}>
                <input
                    ref={panoramicImagePickRef}
                    type="file"
                    accept="image/png, image/jpeg"
                    //hidden
                    onChange={onPanoramicImageChange}
                />
                <Button
                    secondary
                    content="Update Panoramic Image"
                    icon="file"
                    onClick={() => panoramicImagePickRef.current.click()}
                    />
                </Form.Group>
            </Form>
        </Card.Content>
        </Card>
    </React.Fragment>

    )
}

export default EditTableUI;
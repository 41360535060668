import React ,{useRef, useState, useEffect}  from "react";
import { Redirect, withRouter } from 'react-router';
import {
    Grid,
    Header as SemanticHeader,
    Card,
    Form,
    Button,
    Select,
    Image,
  } from "semantic-ui-react";
  import axios from 'axios';
  import S3 from "react-aws-s3";
import { REGISTRATION_TOKEN, URL, IMAGE_URL, SERVER_KEY } from "../firebase/config";
import firebase from "../firebase/config";
import { notificationType } from "../utils/constants";
import { config } from "../s3/config";
import { guidGenerator } from "./GuidGenerator";

const CreateMessage = () => {
    const [isBusy, setIsBusy] = useState(false);
    const [message, setMessage] = useState(() => {
        return {
          title: '',
          description:'',
          type: '',
          code:''
        }
    });
    const coverImagePickRef = useRef(null);
    const [fileName, setFileName] = useState('');
    const { title, description, type, code } = message;
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [tokens, setTokens] = useState([]);

    const getTokens = async() => {
        let tokenArray = [];
        const _tokens = await firebase.getTokens().catch(err =>{
            console.log(err);
            return err;
        });
        _tokens.map(collection => (
            tokenArray.push(collection.data.token)
        ))
        setTokens(tokenArray);
    }

    useEffect(() => {
        getTokens();
    },[])

    const handleInputChange = (e, { name, value }) => {
        setMessage((prevState) => ({
            ...prevState,
            [name]: value
        }));
      };

    const onCoverImageChange = (e) => {
    e.persist();
    const fileURL = e.target.files[0];
    console.log(fileURL.size)
    if(fileURL.size > 10000){
        setErrorMsg("Keep Image Size Below 100KB")
    }
    setFileName(fileURL);
    };

    function sendNotification(message) {
        console.log('message',message)
        let  successMsg = '';
        tokens.forEach(element => {
            const payload = {
                "to": element,
                "notification": {
                    "title": message.title,
                    "body": message.description,
                    "mutable_content": true
                    },
                
                "data": {
                    "img_url": message.coverURL,
                    "category": message?.type,
                    "code": message?.code
                    }
                }
    
             axios
                .post(URL, payload, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                        "Authorization": SERVER_KEY
                    },
                })
            .then(({data}) => {
                setIsBusy(false);
                if(data.success){
                    console.log(data.results)
                    successMsg = 'Message sent';
                }
                console.log(data);
                setSuccessMsg(successMsg);
                // setMessage({
                //     title: '',
                //     description: ''
                // });
            });
            
        });

    }

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        setIsBusy(true);
        const values = [title, description, type];
        let errorMsg = '';
    
        const allFieldsFilled = values.every((field) => {
          const value = `${field}`.trim();
          return value !== '' && value !== '0';
        });
        if (allFieldsFilled) {

        const ReactS3Client = new S3(config);
        let coverURL = '';
        let coverFileName = guidGenerator().toString();
        await ReactS3Client.uploadFile(fileName, coverFileName).then((data) => {

          coverURL = data.location;
            const message = {
              title,
              description,
              type,
              coverURL,
              code
            };
            


          if (data.status === 204) {
            console.log("success");
          } else {
            console.log("fail");
          }
          sendNotification(message);
        });
    } else {
        errorMsg = 'Please fill out Title and description';
      }
      setErrorMsg(errorMsg);
      };
    
    return (
        <React.Fragment>
            <div className="main-form">
                <Grid centered>
                    <Grid.Column className="form-column">
                        <SemanticHeader>Create Message</SemanticHeader>
                        <Card fluid>
                            <Card.Content>
                                <Form unstackable>
                                    <Form.Input
                                        name="type"
                                        onChange={handleInputChange}
                                        control={Select}
                                        options={notificationType}
                                        label="Notification Type"
                                        placeholder="Notification Type"
                                    />
                                    <Form.Input
                                        label="Title"
                                        name="title"
                                        value = {message.title}
                                        onChange={handleInputChange}
                                        placeholder="Title"
                                    />
                                    <Form.TextArea
                                        name="description"
                                        value = {message.description}
                                        onChange={handleInputChange}
                                        label="Description"
                                        placeholder="Description"
                                    />
                                    <Form.Input
                                        label="Code"
                                        name="code"
                                        value = {message.code}
                                        onChange={handleInputChange}
                                        placeholder="Code"
                                    />

                                    <label className="label">
                                        Choose Image (Below 100 KB File Size)
                                    </label>
                                    <div className="btn">
                                        <input
                                            ref={coverImagePickRef}
                                            type="file"
                                            accept="image/png, image/jpeg"
                                            onChange={onCoverImageChange}
                                        />
                                    </div>

                                    <div className="btn">
                                    {errorMsg && <p className="errorMsg">{errorMsg}</p>}
                                    <Button
                                        disabled={isBusy}
                                        loading={isBusy}
                                        onClick={handleOnSubmit}
                                        primary
                                        type="submit"
                                        >
                                        Submit
                                    </Button>
                                    </div>
                                    {successMsg && <p className="successMsg">{successMsg}</p>}
                                </Form>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid>
            </div>
        </React.Fragment>
    )
}

export default withRouter(CreateMessage);
import React from 'react';
import {posts} from "../reducers/postsReducer";

export const Posts = React.createContext();
const initialState = {
    posts: [],
    isSearchActive: false,
    foundContacts: [],
}

export const PostsProvider = (props) =>{
    const [carState, carDispatch] = React.useReducer(posts, initialState);
    const value = {carState, carDispatch};

    return <Posts.Provider value={value}>
                {props.children}
           </Posts.Provider>
}
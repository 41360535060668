import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/storage";
import axios from 'axios';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
}
export const IMAGE_URL = "https://i.picsum.photos/id/1/200/300.jpg?hmac=jH5bDkLr6Tgy3oAg5khKCHeunZMHq0ehBZr6vGifPLY";
export const REGISTRATION_TOKEN = 'czd4XvEHBEIwgYeH8YQZeA:APA91bEq-7KWGTfVnH05liTSK0PCqiGuRWfNnYprzKNZuIi_FQry9gF0qnXJn1BABlyWwVfMFLtwrqMpi2RRGnUPp-tQXW3-FlXk16jTSoezPTbc1If5ymLWFKTIGT8duPlAVilspd9J';
export const URL = 'https://fcm.googleapis.com/fcm/send';
export const SERVER_KEY = 'key=AAAAAkWZsL0:APA91bFoA_nuPHn1ihbvaRXhVFw4f9hItVsLWO1jWQPbt6WyVuXwdnuf1AtMGV6Wy16g8wyhurMh1nTy3dcceifIweHGALqzol9BYAU4b3Ivj0kEbQBPL0W1phIn1Xke4zettIXcZC8J';
// const messagePayload = {
//     "aps" : {
//       "alert" : {
//         "body" : "aboo great match!",
//         "title" : "Portugal vs. Denmark",
//       },
//       "badge" : 1,
//     },
//     "customKey" : "customValue"
//   }
function messagePayload(details){
    const payload = {
        "to": REGISTRATION_TOKEN,
        "notification": {
            "title": "BrandNew  Arrived "+details,
            "body": "This message generated From Admin App",
            "mutable_content": true
            },
        
        "data": {
        "url": IMAGE_URL
            }
        }
        return payload;
}

class Firebase{

    constructor(){
        firebase.initializeApp(config);
        this.auth = firebase.auth();
        this.db = firebase.firestore();
        this.storage = firebase.storage();
    }

    //FCM Notification using REST API

    async sendNotification(details) {
        await axios
            .post(URL, messagePayload(details), {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    "Authorization": "key=AAAAAkWZsL0:APA91bFoA_nuPHn1ihbvaRXhVFw4f9hItVsLWO1jWQPbt6WyVuXwdnuf1AtMGV6Wy16g8wyhurMh1nTy3dcceifIweHGALqzol9BYAU4b3Ivj0kEbQBPL0W1phIn1Xke4zettIXcZC8J"
                },
            })
        .then(({data}) => {
            console.log(data);
        });
    }

    //login
    async login({ password, email }){
        const user = await firebase.auth().signInWithEmailAndPassword(email, password).catch(err => {
            console.log(err);
            return err;
        });
        return user;
    }

    //signup
    async signup({email, password, username}){
        const user = await firebase.auth().createUserWithEmailAndPassword(email, password, username).catch(err => {
            console.log(err);
            return err;
        });
        return user;
    }

    //logout
    async logout(){
        const logout = await firebase.auth().signOut().catch(err => {
            console.log(err);
            return err;
        });
        return logout;
    }


    async getUserState(){
        return new Promise(resolve=> {
            this.auth.onAuthStateChanged(resolve);
        });
    }

    async getTokens(){
        let tokens = [];
        const tokenData = await firebase.firestore().collection("devices").get();
        tokenData.forEach(doc => {
            tokens.push({id:doc.id, data:doc.data()});
        })
        return tokens;
    }

    async getPosts(){
        let postsArray = [];
        const posts = await firebase.firestore().collection("CarList").orderBy('date','desc').get();
        // const posts = await firebase.firestore().collection("CarList").where("code", "==", "021682").get();
        posts.forEach(doc => {
            postsArray.push({id:doc.id, data: doc.data()});
        });
        return postsArray;
    }


    async getPost(postid){
        const post = await firebase.firestore().collection("CarList").doc(postid).get();
        const postData = post.data();
        return postData;
    }


   async createPost(coverURL, panoramicURL, imageUrlArray, post){
       console.log('psot data from config>.',post)
    //const coverFileRef = await firebase.storage().refFromURL(coverURL);
    //const panoramicFileRef = await firebase.storage().refFromURL(panoramicURL);

    let images = Object.values(imageUrlArray)
     let newPost = {
        brand: post.brand,
        model: post.model,
        title: post.title,
        cover_pic: coverURL,
        panoramic: panoramicURL,
        images: images,
        body_type: post.body_type,
        color: post.color,
        condition: post.condition,
        doors: post.doors,
        engine_type: post.engine_type,
        power: post.power,
        price: post.price,
        specification: post.specification,
        torque: post.torque,
        transmission: post.transmission,
        isSold: post.isSold,
        isPrice: post.isPrice,
        z_h: post.z_h,
        km_driven:post.km_driven,
        fuel: post.fuel,
        mfd_year: post.mfd_year,
        code: post.code,
        extras: post.extras,
        date: post.date,
        //coverFileRef : coverFileRef.fullPath,
        //panoramicFileRef : panoramicFileRef.fullPath 
     } 

    console.log('sending firebase last:',newPost)
    const firestorePost = await firebase.firestore().collection("CarList").add(newPost).catch(err => {
        console.log(err);
    });

     return firestorePost;
// let image = post.cover;
//     await firebase.storage().ref(`/image/${image.name}`).put(image).then(({ref})=>{

//         return ref.getDownloadURL().then((url)=>{

//            firebase.firestore().collection('CarList').add({
//                 body_type: post.body_type,
//                 brand: post.brand,
//                 color: post.color,
//                 condition: post.condition,
//                 cover_pic: url,
//                 doors: post.doors,
//                 engine_type: post.engine_type,
//                 images: {image_01: url},
//                 model: post.model,
//                 power: post.power,
//                 price: post.price,
//                 specification: post.specification,
//                 torque: post.torque,
//                 transmission: post.transmission,
//                 isSold: post.isSold,
//                 z_h: post.z_h,
//                 km_driven:post.km_driven,
//                 date: post.date,
//                 coverFileRef : ref.fullPath 
//            })
//         })
//         })
  
   }

  
   async updatePost(postid, postData){
    // let updatedPost = {
    //     brand: postData.brand,
    //     body_type: postData.body_type,
    //     color: postData.color,
    //     condition: postData.condition,
    //     doors: postData.doors,
    //     engine_type: postData.engine_type,
    //     model: postData.model,
    //     power: postData.power,
    //     specification: postData.specification,
    //     torque: postData.torque,
    //     transmission: postData.transmission,
    //     z_h: postData.z_h,
    //     km_driven: postData.km_driven,
    //     price: postData.price,
    //     fuel: postData.fuel,
    // }
    const post = await firebase.firestore().collection("CarList").doc(postid).set(postData, {merge: true}).catch(err => {
        console.log(err);
    });
    return post
   }

   async updateImage(imageUrl, postid, keyUrl, keyRef) {
        //const fileRef = await firebase.storage().refFromURL(imageUrl);
        let updatedPost = {
            [keyUrl]: imageUrl,
            //[keyRef] : fileRef.fullPath
        }
        const post = await firebase.firestore().collection("CarList").doc(postid).set(updatedPost, {merge: true}).catch(err => {
            console.log('update post error:',err);
        });
        return post;
   }

   async updateMultipleImage(imageUrlArray, postid) {
    let images = Object.values(imageUrlArray);
    const imagesRef = firebase.firestore().collection("CarList").doc(postid);
    const post = await imagesRef.update({
        images: firebase.firestore.FieldValue.arrayUnion(...images)
    });
    return post;
   }

   async deleteImageFromArray(postid,url) {
        const imagesRef = firebase.firestore().collection("CarList").doc(postid);
        const post = await imagesRef.update({
            images: firebase.firestore.FieldValue.arrayRemove(url)
        });
        console.log('image removed')
        return post;
   }


   async deletePost(postid, coverFileRef){
        // const storageRef = firebase.storage().ref();
        // await storageRef.child(coverFileRef).delete().catch(err => {
        //     console.log(err);
        // });
        // console.log("Image Deleted");
        const post = await firebase.firestore().collection("CarList").doc(postid).delete().catch(err => {
            console.log(err);
        });
        console.log("Post Deleted");

        return post;
    }

}

export default new Firebase();
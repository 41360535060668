import {  SEARCH_CARS,
          FETCH_POSTS
} from "../constants/actionTypes";

export const posts = (state, { payload, type }) => {

    switch(type){
        case FETCH_POSTS:
            return{...state, 
                posts: {
                    ...state.posts,
                    data:payload
            }
        }

        case SEARCH_CARS: {
            const searchValue = payload?.toLowerCase();
            return {
                ...state,
                posts: {
                ...state.posts,
                isSearchActive: !!payload.length > 0 || false,
                foundCars: state.posts.data.filter((item) => {
                    try {
                    return (
                        item.data.code.toLowerCase().search(searchValue) !== -1
                    );
                    } catch (error) {
                    return [];
                    }
                }),
                },
            };
            }
        
        default:
            return state;

      
    }
}
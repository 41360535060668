export const Brands =  [
    {key: 1, value: 'Aston Martin', text: 'Aston Martin'},
    {key: 2, value: 'Audi', text: 'Audi'},
    {key: 3, value: 'Bentley', text: 'Bentley'},
    {key: 4, value: 'BMW', text: 'BMW'},
    {key: 5, value: 'Bugatti', text: 'Bugatti'},
    {key: 6, value: 'Cadillac', text: 'Cadillac'},
    {key: 7, value: 'Dodge', text: 'Dodge'},
    {key: 8, value: 'Ferrari', text: 'Ferrari'},
    {key: 9, value: 'Ford', text: 'Ford'},
    {key: 10, value: 'Koenigsegg', text: 'Koenigsegg'},
    {key: 11, value: 'Lamborghini', text: 'Lamborghini'},
    {key: 12, value: 'Land Rover', text: 'Land Rover'},
    {key: 13, value: 'Lexus', text: 'Lexus'},
    {key: 14, value: 'Lincoln', text: 'Lincoln'},
    {key: 15, value: 'MINI', text: 'MINI'},
    {key: 16, value: 'Maserati', text: 'Maserati'},
    {key: 17, value: 'Maybach', text: 'Maybach'},
    {key: 18, value: 'McLaren', text: 'McLaren'},
    {key: 19, value: 'Mercedes Benz', text: 'Mercedes Benz'},
    {key: 20, value: 'Morgan', text: 'Morgan'},
    {key: 21, value: 'Nissan', text: 'Nissan'},
    {key: 22, value: 'Pagani', text: 'Pagani'},
    {key: 23, value: 'Porsche', text: 'Porsche'},
    {key: 24, value: 'Rolls Royce', text: 'Rolls Royce'},
    {key: 25, value: 'Tesla', text: 'Tesla'},
    {key: 26, value: 'Toyota', text: 'Toyota'},
    {key: 27, value: 'W Motors', text: 'W Motors'},
    {key: 28, value: 'Other', text: 'Other'},
];

export const BodyTypes = [
    {key: 'coupe', value: 'Coupe', text: 'Coupe'},
    {key: 'sfc', value: 'Soft top Convertible', text: 'Soft top Convertible'},
    {key: 'htc', value: 'Hard top convertible', text: 'Hard top convertible'},
    {key: 'hb', value: 'Hatchback', text: 'Hatchback'},
    {key: 'lmss', value: 'Lux Mid size Sedan', text: 'Lux Mid size Sedan'},
    {key: 'lfss', value: 'Lux Full size Sedan', text: 'Lux Full size Sedan'},
    {key: 'sd', value: 'Sedan', text: 'Sedan'},
    {key: 'put', value: 'Pick Up Truck', text: 'Pick Up Truck'},
    {key: 'sc', value: 'Sport Car', text: 'Sport Car'},
    {key: 'cs', value: 'Crossover SUV', text: 'Crossover SUV'},
    {key: 'suv', value: 'SUV', text: 'SUV'},
    {key: 'van', value: 'Van', text: 'Van'},
    {key: 'wagn', value: 'Wagon', text: 'Wagon'},
    {key: 'oth', value: 'Other', text: 'Other'},
];

export const Color = [
    {key: 1, value: 'Black', text: 'Black'},
    {key: 2, value: 'Blue', text: 'Blue'},
    {key: 3, value: 'Brown', text: 'Brown'},
    {key: 4, value: 'Beige', text: 'Beige'},
    {key: 5, value: 'Burgundy', text: 'Burgundy'},
    {key: 6, value: 'Gold', text: 'Gold'},
    {key: 7, value: 'Grey', text: 'Grey'},
    {key: 8, value: 'Nardo Grey', text: 'Nardo Grey'},
    {key: 9, value: 'Green', text: 'Green'},
    {key: 10, value: 'Orange', text: 'Orange'},
    {key: 11, value: 'Pink', text: 'Pink'},
    {key: 12, value: 'Purple', text: 'Purple'},
    {key: 13, value: 'Red', text: 'Red'},
    {key: 14, value: 'Silver', text: 'Silver'},
    {key: 15, value: 'Tan', text: 'Tan'},
    {key: 16, value: 'Teal', text: 'Teal'},
    {key: 17, value: 'Two tones color', text: 'Two tones color'},
    {key: 18, value: 'Violet', text: 'Violet'},
    {key: 19, value: 'White', text: 'White'},
    {key: 20, value: 'Yellow', text: 'Yellow'},
    {key: 21, value: 'Other Color', text: 'Other Color'},
];

export const Doors = [
    {key: 'two', value: 'Two', text: 'Two'},
    {key: 'three', value: 'Three', text: 'Three'},
    {key: 'four', value: 'Four', text: 'Four'},
    {key: 'five', value: 'Five', text: 'Five'},
    {key: 'other', value: 'Other', text: 'Other'},
];

export const Transmission = [
    {key: 'aut', value: 'Automatic', text: 'Automatic'},
    {key: 'man', value: 'Manual', text: 'Manual'},
];

export const Fuel = [
    {key: 'gas', value: 'Gasoline', text: 'Gasoline'},
    {key: 'hyb', value: 'Hybrid', text: 'Hybrid'},
    {key: 'elc', value: 'Electric', text: 'Electric'},
];

export const RegionalSpecs = [
    {key: 'gc', value: 'GCC Specs', text: 'GCC Specs'},
    {key: 'eu', value: 'European Specs', text: 'European Specs'},
    {key: 'jp', value: 'Japanese Specs', text: 'Japanese Specs'},
    {key: 'na', value: 'North American Specs', text: 'North American Specs'},
    {key: 'am', value: 'American Specs', text: 'American Specs'},
    {key: 'oth', value: 'Other', text: 'Other'},
];

export const Cylinder = [
    {key: 1, value: '3', text: '3'},
    {key: 2, value: '4', text: '4'},
    {key: 3, value: '6', text: '6'},
    {key: 4, value: '8', text: '8'},
    {key: 5, value: '10', text: '10'},
    {key: 6, value: '12', text: '12'},
    {key: 7, value: '16', text: '16'},
    {key: 8, value: 'Other', text: 'Other'},
];

export const notificationType = [
    {key:1, value: 'Advertisement', text: 'Advertisement'},
    {key:2, value:'New Car', text:'New Car'}
]

import React from "react";
import { Switch, Route } from "react-router-dom";

//components
import Dashboard from "./components/Dashboard";
// import Signup from "./components/Signup";
import Login from "./components/Login";
import Create from "./components/CreatePost";
import PostDetails from "./components/PostDetails";
import CreateMessage from "./components/CreateMessage";

const Routes = () => (

    <Switch>
        <Route exact path="/" component = {Dashboard} />
        {/* <Route exact path="/signup" component = {Signup} /> */}
        <Route exact path="/login" component = {Login} />   
        <Route exact path="/create" component = {Create} />
        <Route exact path="/post/:id" component = {PostDetails} />
        <Route exact path="/message" component={CreateMessage} />
    </Switch>

);

export default Routes;